<template>
  <Modal ref="edit_user_modal" title="Редактировать информацию">
    <v-form class="edit-user-form">
      <v-text-field
        v-model="user.name"
        placeholder="Не указано"
        label="Имя"
      />
      <v-text-field
        v-model="user.surname"
        placeholder="Не указана"
        label="Фамилия"
      />
      <v-text-field
        :key="user.phone"
        v-model="user.phone"
        disabled
        placeholder="Не указан"
        label="Телефон"
        v-maska="['+# (###) ###-##-##', '+### (##) ###-##-##']"
      />
      <v-text-field
        v-model="user.email"
        placeholder="Не указан"
        label="Email"
        hide-details="auto"
        :error-messages="errors.text"
        @input="errors = {}"
      class="mb-4"
      />
      <v-text-field label="Дата рождения" v-model="user.birthday" v-maska="'##.##.####'"/>
    </v-form>
    <template v-slot:actions>
      <v-btn class="login-phone-btn" depressed color="primary" @click="updateUser" :loading="updateUserLoading">Готово</v-btn>
    </template>
  </Modal>
</template>

<script>
export default {
  name: 'EditUserModal',
  components: {},
  data() {
    return {
      dateMenu: false,
      user: {},
      updateUserLoading: false,
      errors: {},
    };
  },
  emits: ['updated'],
  methods: {
    open(user) {
      this.user = {};
      this.errors = {};
      this.$refs.edit_user_modal.open();
      this.user = { ...user };
      this.user.birthday = user.birthday.split('-')
        .reverse()
        .join('.');
    },
    close() {
      this.$refs.edit_user_modal.close();
    },
    prepareData(data) {
      let preparedData = { ...data };
      preparedData.birthday = preparedData.birthday.split('.')
        .reverse()
        .join('-');
      if (preparedData.image && !['', '0'].includes(preparedData.image.id)) {
        preparedData.image = preparedData.image.id;
      }
      return preparedData;
    },
    updateUser() {
      this.updateUserLoading = true;
      this.$server.request2('user/update', this.prepareData(this.user), (data) => {
        this.updateUserLoading = false;
        this.$emit('updated', data.user);
        this.close();
      }, (data) => {
        this.errors = data.error;
        this.updateUserLoading = false;

      });
    },
  },

};
</script>

<style scoped lang="scss">
.edit-user-form {
  display: flex;
  flex-direction: column;
}
</style>

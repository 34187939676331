<template>
  <div class="invite-block">
    <div class="invite-block_media">
      <div class="icon icon-money"></div>
    </div>
    <div class="invite-block_title">Пригласите друга</div>
    <div class="invite-block_subtitle">Получите оба по 1000 рублей</div>
    <div class="invite-block_list">
      <ul>
        <li>
          <div class="invite-block_list-item">
            <div class="invite-block_list-item_text">Приглашено</div>
            <div class="invite-block_list-item_value">0</div>
          </div>
        </li>
        <li>
          <div class="invite-block_list-item">
            <div class="invite-block_list-item_text">Получено бонусов</div>
            <div class="invite-block_list-item_value">0</div>
          </div>
        </li>
      </ul>
    </div>
    <div class="cancel-button">Копировать ссылку</div>
  </div>
</template>

<script>
export default {
  name: 'InviteBlock',
};
</script>

<style lang="scss">
.invite-block {
  width: 100%;
  padding: 28px;
  background: #FFFFFF;
  box-shadow: 2px 8px 30px rgba(0, 0, 0, 0.2);
  border-radius: 12px;

  &_media {
    width: 55px;
    height: 55px;
    margin: 0 auto 18px auto;
    background: var(--color-gray);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    .icon {
      font-size: 24px;
    }
  }

  &_title {
    margin-bottom: 5px;
    text-align: center;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }

  &_subtitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 13px;
    line-height: 20px;
    color: rgba(var(--page-color-main-rgb), .5);
  }

  &_list {
    margin-bottom: 35px;

    ul {
      padding: 0;
      margin: 0;
      list-style-type: none;
    }

    li {
      border-bottom: 3px solid var(--color-gray);
    }
  }

  &_list-item {
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &_text {
      font-size: 14px;
      line-height: 16px;
    }

    &_value {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
    }
  }
}
</style>

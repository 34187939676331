<template>
  <div class="page half-width">
    <div class="page-content">
      <div class="profile-user">
        <div class="profile-user-image" @click="changePhoto">
          <div class="profile-user-image_edit-icon">
            <div class="icon icon-pencil"></div>
          </div>
          <img
            v-if="user.image && user.image.url"
            :src="user.image.url"
            alt="user image"
          >
          <div class="no-img" v-else>
            <div class="icon icon-profile"></div>
          </div>
        </div>
        <div class="profile-user-data" @click="$refs.edit_user_modal.open(user)">
          <div v-if="Object.keys(user).length > 0" class="profile-user-data_fields">
            <div class="profile-user-name">{{ user.name }} {{ user.surname }}</div>
            <div class="profile-user-phone" v-if="user.phone">
              {{ convertPhoneStr(user.phone) }}
            </div>
            <div class="profile-user-email" v-if="user.email">{{ user.email }}</div>
          </div>
          <v-skeleton-loader v-else width="300" type="list-item-three-line"/>
          <div class="profile-user-data_edit-button transparent-button">Редактировать</div>
        </div>
      </div>
      <div class="profile-user-settings">
        <div class="profile-user-settings-title mb-4">Настройки модалок</div>
        <v-checkbox
          :input-value="user.settings && user.settings.success_close_vacancy_modal ? user.settings.success_close_vacancy_modal : 0"
          label="Не показывать фейрверк после закрытия вакансии" :true-value="1" :false-value="0" @change="onChangeShowWarn('success_close_vacancy_modal', $event)" class="alert-checkbox"/>
        <v-checkbox
          :input-value="user.settings && user.settings.show_notify_contacts ? user.settings.show_notify_contacts : 0"
          label="Не предлагать уведомлять кандидата о собеседовании" :true-value="1" :false-value="0" @change="onChangeShowWarn('show_notify_contacts', $event)" class="alert-checkbox"/>
      </div>
      <template v-if="payment">
        <PaymentCardsList/>
        <div class="subscription-info">
          <div class="subscription-info_title">Информация о подписке</div>
          <div class="subscription-info_points"></div>
          <div class="subscription-info_point">
            <div class="subscription-info_point_title">Стоимость</div>
            <div class="subscription-info_point_value">699₽/месяц, первые 2 месяца бесплатно</div>
          </div>
          <div class="subscription-info_point">
            <div class="subscription-info_point_title">Подписка</div>
            <div class="subscription-info_point_value">Ежемесячная</div>
          </div>
          <div class="subscription-info_point">
            <div class="subscription-info_point_title">Оплачено до</div>
            <div class="subscription-info_point_value">{{ datePayedTo }}</div>
          </div>
          <div class="subscription-info_point">
            <div class="subscription-info_point_title">Будет продлена</div>
            <div class="subscription-info_point_value">{{ dateRenew }}</div>
          </div>
        </div>
        <p>После привязки карты активируется бесплатный доступ на 2 месяца.
          После истечения бесплатного периода, каждый месяц сумма за подписку будет автоматически списываться с привязанной карты. Подписку можно
          отменить в любой момент в личной кабинете Rekroo, отвязав в профиле карту.</p>
        <div class="agreement-text">
          <a class="external" target="_blank" href="https://rekroo.org/terms">
            Условия использования сервиса</a>,
          <a class="external" target="_blank" href="https://rekroo.org/privacy">
            Политика конфеденциальности.
          </a>
        </div>
      </template>
    </div>
    <div class="page-right-content">
      <!--      <InviteBlock/>-->
    </div>
    <EditUserModal ref="edit_user_modal" @updated="onUserUpdated"/>
  </div>
</template>

<script>
import InviteBlock from '@/views/profile/InviteBlock.vue';
import PaymentCardsList from '@/views/payment/PaymentCardsList.vue';
import EditUserModal from '@/views/profile/EditUserModal.vue';

export default {
  name: 'Profile',
  components: {
    PaymentCardsList,
    InviteBlock,
    EditUserModal,
  },
  data() {
    return {
      payment: false
    };
  },
  methods: {
    updateUser(data) {
      this.$server.request2('user/update', data, (data)=>{
        console.log(data.user);
        this.$store.commit('setUserData', data.response);
        this.$forceUpdate();
      })
    },
    changePhoto() {
      const inputFile = document.createElement('input');
      inputFile.setAttribute('type', 'file');
      inputFile.setAttribute('accept', 'image/*');
      inputFile.addEventListener('change', (e) => {
        if (e.target.files.length) {
          this.$server.uploadFile(e.target.files[0])
            .then((dataResponse) => {
              this.updateUser({ image: dataResponse.response.id });
            });
        }
      });
      inputFile.click();
    },
    onUserUpdated(newUser) {
      this.$store.state.user = newUser;
    },
    onChangeShowWarn(name, value) {
      this.$server.request2('user/setSetting', {name, value}, (data)=>{
        this.$store.commit('setUserData', data.user);
      });
    }
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    datePayedTo() {
      const value = this.$store.state.user.access_date_to;
      if (!value) return null;
      return this.$moment(value)
        .locale('ru')
        .format('DD MMMM, ddd');
    },
    dateRenew() {
      const value = this.$store.state.user.access_date_to;
      if (!value) return null;
      return this.$moment(value)
        .locale('ru')
        .add(1, 'days')
        .format('DD MMMM, ddd');
    },
  },
};
</script>

<style scoped lang="scss">
.profile-user {
  margin-bottom: 50px;
  display: flex;

  .profile-user-image {
    position: relative;
    flex-shrink: 0;
    margin-right: 38px;
    width: 100px;
    height: 100px;
    cursor: pointer;
    border-radius: 50%;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }

    .no-img {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--border-color);
      border-radius: 50%;

      .icon {
        font-size: 56px;
      }
    }

    &_edit-icon {
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #FFFFFF;
      box-shadow: 2px 4px 16px rgba(0, 0, 0, 0.2);
      border-radius: 6px;

      .icon {
        font-size: 16px;
        font-weight: bold;
      }
    }
  }

  .profile-user-data {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;

    &_fields {
      & > * {
        margin-bottom: 4px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &_edit-button {
      margin-top: 12px;
      text-align: left;
    }
  }

  .profile-user-name {
    font-weight: bold;
    font-size: 34px;
    line-height: 34px;
    letter-spacing: -1px;
  }
}

.profile-user-settings {
  &-title {
    font-weight: bold;
    font-size: 22px;
    letter-spacing: -1px;
  }
  .alert-checkbox {
    margin-left: 0;
  }
}
.subscription-info {

  &_title {
    font-weight: bold;
    font-size: 22px;
    line-height: 32px;
    letter-spacing: -0.5px;
  }

  &_points {
    margin-top: 18px;
    border-top: 1px solid var(--border-color);
  }

  &_point {
    border-bottom: 1px solid var(--border-color);
    display: flex;
    justify-content: space-between;
    padding: 12px 0;

    &_value {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
    }

    &_title {
      font-size: 14px;
      line-height: 16px;
    }
  }
}

.page {
  &::v-deep(.card-list) {
    margin-bottom: 30px;
  }
}
</style>

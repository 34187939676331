<template>
  <div class="card-list">
    <div class="card-list_header">
      <div class="card-list_title">Карты оплаты подписки</div>
      <div class="card-list_add" @click="addCardEmit">
        <div class="icon icon-new"></div>
        <span>Привязать карту</span>
      </div>
    </div>
    <ul v-if="cards.length">
      <li v-for="card in cards" :key="card.CardId">
        <div
          class="card-list_item"
        >
          <div class="card-list_item_media">
            <div class="icon icon-info"></div>
          </div>
          <div class="card-list_item_inner">
            <div class="card-list_item_inner_row">
              <span class="card-list_item_subtitle">Последние цифры: </span>
              <span class="card-list_item_title">{{ card.CardName.slice(-4) }}</span>
            </div>
          </div>
          <div class="card-list_item_after">
            <div class="default-card-label" v-if="isDefaultCard(card)">
              Основная карта
            </div>
            <div class="cancel-button" v-else @click="setDefaultCard(card)">
              Сделать основной
            </div>
            <div class="icon icon-trash" @click.stop="removeCardConfirmOpen(card.CardId)"/>
          </div>
        </div>
      </li>
    </ul>
    <NoResults v-else>
      <template v-slot:title>Карт нет</template>
      <template v-slot:text>
        Для оформления подписки привяжите карту
      </template>
    </NoResults>
    <Confirm ref="confirm-remove" message="Удалить карту?" @accept="removeCard"/>
  </div>
</template>

<script>
export default {
  name: 'PaymentCardsList',
  methods: {
    removeCardConfirmOpen(id) {
      this.idForRemoving = id;
      this.$refs['confirm-remove'].open();
    },
    removeCard() {
      this.$server.request('user/removeCard', { cardID: this.idForRemoving })
        .then(() => this.$store.commit('removeCard', this.idForRemoving));
    },
    loadList() {
      return this.$server.request('user/cardsList')
        .then(({ response }) => response.forEach((card) => this.$store.commit('setCard', card)));
    },
    setDefaultCard(card) {
      const defaultCard = card.CardName;
      this.$server.request('user/update', { defaultCard })
        .then(({ response }) => this.$store.commit('setUserData', {
          ...this.$store.state.user,
          ...response,
        }));
    },
    addCardEmit() {
      this.$eventBus.emit('add-card-modal-open', {
        on: {
          cardAdded: (modal) => {
            this.loadList();
            modal.close();
          },
        },
      });
    },
    isDefaultCard(card) {
      return card.CardName === this.$store.state.user.defaultCard;
    },
  },
  computed: {
    cards() {
      return this.$store.state.cards;
    },
  },
  created() {
    this.loadList();
  },
};
</script>

<style scoped lang="scss">
.card-list {
  &_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
  }

  &_title {
    font-weight: bold;
    font-size: 22px;
    line-height: 32px;
    letter-spacing: -0.5px;
  }

  &_add {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.25px;
    color: var(--color-blue);
    display: flex;
    align-items: center;
    cursor: pointer;

    .icon {
      margin-right: 6px;
      font-size: 12px;
      font-weight: bold;
    }
  }

  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
    border-top: 1px solid var(--border-color);
  }

  li {
    border-bottom: 1px solid var(--border-color);
  }

  &_item {
    cursor: pointer;
    display: flex;

    &_media {
      flex-shrink: 0;
      margin-right: 20px;
      padding: 12px 0;

      .icon {
        width: 54px;
        height: 54px;
        font-size: 24px;
        background: var(--border-color);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
      }
    }

    &_inner {
      width: 100%;
      min-width: 0;
      padding: 18px 0;
      display: flex;
      flex-direction: column;
      justify-content: center;

      & > * {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        margin-bottom: 5px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &_title {
      font-weight: 600;
      font-size: 18px;
      line-height: 18px;
    }

    &_subtitle {
      font-size: 16px;
      line-height: 16px;
      color: rgba(var(--page-color-main-rgb), .5)
    }

    &_after {
      flex-shrink: 0;
      display: flex;
      align-items: center;

      & > .icon {
        padding: 0 14px;
        height: 100%;
        display: flex;
        align-items: center;
        font-size: 24px;
        color: rgba(var(--page-color-main-rgb), .25);

        &:last-child {
          padding-right: 0;
        }
      }
    }
  }

  .no-results-block {
    margin-top: 50px;
  }
  .default-card-label {
    width: 100%;
    padding: 11px;
    display: block;
    text-align: center;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.25px;
    color: #FFFFFF;
    background: var(--page-color-main);
    border-radius: 5px;
  }
}
</style>
